import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from './HomePage/HomePage';
import TeamPage from './TeamPage/TeamPage';
import Layout from './common/Layout';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path="TeamPage" element={<TeamPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}




export default App;
