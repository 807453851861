import { Image } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import './Laptop.css';
import LaptopImage from './../Images/Laptop.svg';
import redShape from './../Images/Pictures/red-shape.PNG'

function Laptop() {
  return ( 
    <div className="">

    <img className='redShapeRights' alt='' src={redShape}></img>

        <Image src={LaptopImage} alt="laptop" fluid />
        <img className='redShapeLefts' alt='' src={redShape}></img>

    </div>

  );
}
export default Laptop;