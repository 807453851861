import React, { useEffect } from 'react'
import Card from 'react-bootstrap/Card';
import Aos from 'aos'
import 'aos/dist/aos.css'

export default function InfoCard(props) {

    useEffect(()=>{
        Aos.init();
       }, [])
       
  return (

<Card  className="margin" style={{ width: '22rem' }} data-aos="fade-up">
      <Card.Img variant="top" src={props.image} />
      <Card.Body>
        <Card.Title>{props.Name}</Card.Title>
        <Card.Subtitle className="muted">{props.job}</Card.Subtitle>
        <Card.Text>
          
        </Card.Text>
      </Card.Body>
    </Card>
  )
}
