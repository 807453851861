import './HomePage.css'
function ArtizanaName() {
  return ( 
    <div dir="rtl" id='home'>
        <p className='large-text-main-color'>أرتيزانا</p>
    </div>

  );
}

export default ArtizanaName;