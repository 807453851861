import { Container, Row, Col } from 'react-bootstrap';
import OrangeButton from '../common/OrangeButton';
import ArtizanaInfo from './ArtizanaInfo';
import ArtizanaName from './ArtizanaName';
import Laptop from './Laptop';
import PhotoCarousel from './PhotoCarousel';
import Market from './Market';
import Ninth from './Form';
import Eighth from './Princess';
import Vision from './Vision';
import Blog from './BlogSection';
import ContactUs from './../common/ContactUs';
import MultiImageCarousel from './MultiImageCarousel';

function HomePage() {
  return (  
    <div className='backColor'>
   <div>
    <Container fluid>
      <Row className="justify-content-md-center text-center">
        <Col>
          <ArtizanaName />
        </Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col md={6}>
        <ArtizanaInfo/>
        </Col>
      </Row>
        <Row className="justify-content-md-center">
            <Col className="d-flex justify-content-center">
            <OrangeButton Name="اطلب الآن" Link="https://www.artizana.market/" />
            </Col>
        </Row>
        <Row className="mt-5 mb-5" fluid>
        <PhotoCarousel/>

            </Row>
    </Container>

</div>
<div className='backColor'>
<Market />

</div>
<div className='orange-background'>
<Laptop />
</div>
<div className='backColor'>

<Container>
  
<Row className="justify-content-md-center">
  <MultiImageCarousel />
    </Row>
    <Row className="justify-content-md-center">
    <Vision />
  </Row>
    
      </Container>
      <Container fluid>
        <Row>
          <Blog/>
        </Row>
      <Row className="justify-content-md-center">
      <Eighth/>
    </Row>

      </Container>
      </div>
            <Ninth className="pt-5"/>

      <ContactUs />
</div>
  );
}

export default HomePage;

