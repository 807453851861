import './HomePage.css';
import ImageCard from './ImageCard';
import Naht from './../Images/Naht.svg';
import Hyaka from './../Images/Hyaka.svg';
import Paint from './../Images/Paint.svg';
import Carpentry from './../Images/Carpentry.svg';
import Pottery from './../Images/Pottery.svg';
import CardCarousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Image } from 'react-bootstrap';
import React from 'react';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
    paritialVisibilityGutter: 0
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    paritialVisibilityGutter: 50
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    paritialVisibilityGutter: 30
  }
};

var imageMoved = false;
class PhotoCarousel extends React.Component  
  { 
  state = { imageMoved: ".dontmove", id:0 };
  render () {
  return ( 
<div>
<CardCarousel
      ssr
      centerMode={false}
      infinite
      autoPlay
      focusOnSelect={false}
      renderArrowsWhenDisabled={false}
      containerClass="container"
      pauseOnHover
      autoPlaySpeed={3000}
      deviceType={this.props.deviceType}
      itemClass="image-item"
      afterChange={nextSlide => {
        this.setState({id: nextSlide});     

      }}
      responsive={responsive}
    >
                <ImageCard title="رسم" picid={7} id={this.state.id} move={this.state.imageMoved} image={Paint}/>
                <ImageCard title="حياكة" picid={8} id={this.state.id} image={Hyaka} />
                <ImageCard title="نحت" picid={9} id={this.state.id} image={Naht}/>
                <ImageCard title="نجارة" picid={5} id={this.state.id} image={Carpentry}/>
                <ImageCard title="فخار" picid={6} id={this.state.id} image={Pottery}/>
    </CardCarousel>
</div>
  );
}
  }
export default PhotoCarousel;

