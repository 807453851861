import React, { useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import OrangeTriangle from './../Images/Orange-Triangle.PNG'
import GrayTriangle from './../Images/Gray-Triangle.png'
import Aos from 'aos'
import 'aos/dist/aos.css'

export default function Vision() {

  useEffect(()=>{
    Aos.init();
   }, [])

  return (
    <Container  dir="rtl">
    
          <Col id='vision'>

           <Row data-aos="zoom-in-up" data-aos-offset="200">
          
            <div className='message Square'>
                <div className='titleVisoin'>رؤيتنا
                <img className='shapes gray' src={GrayTriangle} alt='Not Found' />
                <img className='shapes hidden' src={OrangeTriangle} alt='Not Found' />

                </div>
                <br></br>
                العمل مع تجارنا على تطوير منتجاتهم حتى نبني سوقا موثوقا للسلع المصنوعه يدويا
                <br></br>
                والتي يمكن للعملاء الوثوق بها ويمكن للحرفيين أن يفخروا بها.
            </div>
        </Row>

        <Row data-aos="zoom-in-up" data-aos-offset="150">
        <div className='message Square'>
             <div className='titleMessage'>رسالتنا
             <img className='shapes gray' src={GrayTriangle} alt='Not Found'/>
             <img className='shapes hidden' src={OrangeTriangle} alt='Not Found' />


             </div>
                <br></br>
                زيادة مبيعات المنتجين وتوسيع أعمالهم ليصبحوا كيانات مستقلة تدعم الاقتصاد الوطني
                <br></br>
                ونشر مفهوم الحفاظ على الموروث الثقافي من الاندثار.
            </div>

        </Row>
        </Col>
    </Container>
  )
}

