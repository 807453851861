import React from 'react'
import { Container, Row } from 'react-bootstrap'
import './Team.css'
import InfoCard from './InfoCard';
import ProfileMale from './../TeamPage/TeamPictures/profile-male.png'
import ProfileFemale from './../TeamPage/TeamPictures/profile-female.png'
import Abdullah from './../TeamPage/TeamPictures/abdullah.jpg'
import Mohammed from './../TeamPage/TeamPictures/Mohammed.jpg'
import Sultan from './../TeamPage/TeamPictures/sultan.jpg'





export default function team() {
  return (
<div className=''>

<Container fluid>
    
<Row className='justify-content-md-center text-center background'>
<div>
اعضاء شركة ارتيزانا السعودية
</div>
</Row>

<Row className='middle'>
    <InfoCard image={Abdullah} job="CEO" Name="Abdullah Almefda"/>
    <InfoCard image={Mohammed} job="Software Developer"Name="Mohammed Alessa"/>
    <InfoCard image={Sultan} job="Software Developer"Name="Sultan Alkanain"/>
</Row>

<Row className='middle'>
    <InfoCard image={ProfileFemale} job="Customer Success Manager"Name="Mona Alghamdi"/>
    <InfoCard image={ProfileFemale} job="Customer Success Manager"Name="Reem Alenzi"/>
    <InfoCard image={ProfileFemale} job="Customer Success Manager"Name="Waad Alshamary"/>
</Row>



</Container>
</div>

  )
}
