import React from 'react'
import { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { Col, Container} from 'react-bootstrap'
import FormButton from './FormButton';

export default function Ninth() {

    const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_s7rng9v', 'template_0q2503q', form.current, '9XiTmnuC1AvUa9f7d')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      form.current.reset()
      alert("شكرا! تم ارسال رسالتك بنجاح");

  };
  

  return (
    
    <Container fluid className="Form pt-5 pb-5" dir="rtl">

    <Col id='form'>
        <p className='contactUs'>تواصل معنا</p>
        <form action='' ref={form} onSubmit={sendEmail} >
            <label for="name" className='contactUs'>الاسم</label>
            <input type='text' id='name' name="user_name" className='formInputs' required></input><br/><br/><br/>
            <label for="phone#" className='contactUs'>رقم الجوال</label>
            <input type='text' id='phone#' name="user_phone" className='formInputs' required></input><br/><br/><br/>
            <label for="message" className='contactUs'>رسالتك</label>
            <textarea type='text' id='message' name="message" className='formMessage' required></textarea>
            <FormButton type="submit" Name="ارسل"/>
        </form>
        <br></br><br></br>
        
    </Col>

    </Container>
  )
}