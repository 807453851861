import { Outlet, Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
const Layout = () => {
  return (
    <div className="backColor">
    <Navbar expand="lg">
      <Container>
         <Navbar.Toggle aria-controls="basic-navbar-nav" />
           <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
            <Nav.Link className="navlink margin-nav" href="#form">تواصل معنا</Nav.Link>
            <Nav.Link className="navlink margin-nav" href="#vision">رؤيتنا</Nav.Link>
            <Nav.Link className="navlink margin-nav" href="#vision">رسالتنا</Nav.Link>
            <Nav.Link>
           <li>
            <Link className="navlink margin-nav text-decoration" to="/TeamPage">فريق العمل</Link>
          </li>
            </Nav.Link>
           </Nav>

           </Navbar.Collapse>
         <Navbar.Brand>
         <li>
            <Link className="main-color text-decoration" to="/">أرتيزانا</Link>
          </li>
            </Navbar.Brand>
      </Container>
    </Navbar>
      

      <Outlet />
    </div>
  )
};

export default Layout;