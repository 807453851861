import React from 'react'
import { Container } from 'react-bootstrap'
import Row from 'react-bootstrap/esm/Row'
import Col from 'react-bootstrap/esm/Col'
import facebook from "./../Images/facebook-icon.PNG"
import phone from './../Images/phone-icon.PNG'
import email from './../Images/email-icon.PNG'
import twitter from './../Images/twitter-icon.PNG'
import youtube from './../Images/youtube-icon.PNG'
import instagram from './../Images/instagram-icon.PNG'
import Artizana from './../Images/Artizana-Logo.PNG'


export default function ContactUs() {
  return (
    <Container fluid>
      <Row className='Dark-Orange padding'>
        <Col className='White' md={{ span: 4, offset: 1 }}>
        <div className='padding11 font'>
        للتواصل           
         </div>
         <div>
         <span className='padding11'>0566606006 <img src={phone} alt='Not Found'></img></span> 
         </div>

         <div>
          <span>abdullah@osrah.store <img src={email} alt='Not Found'></img></span>
         </div>

         <div>
          <span> 
            <a href="https://www.facebook.com/profile.php?id=100064127108929" target="_blank" rel="noreferrer">
          <img className='padding' src={facebook} alt='Not Found'></img>
          </a>

          <a href="https://twitter.com/Artizana_store" target="_blank" rel="noreferrer">
          <img className='padding' src={twitter} alt='Not Found'></img>
          </a>

          <a href="https://www.youtube.com/" target="_blank" rel="noreferrer">
          <img className='padding' src={youtube} alt='Not Found'></img>
          </a>

          <a href="https://www.instagram.com/artizana.store/" target="_blank" rel="noreferrer">
          <img className='padding' src={instagram} alt='Not Found'></img>
          </a>

          </span>
         </div>
        </Col>
        <Col  md={{ span: 4, offset: 2 }}>
        <div className='right'>
        <img src={Artizana} alt='Not Found'></img>
        </div>
        
        </Col>
      </Row>
    </Container>
  )
}
