import React from 'react'
import { Container } from 'react-bootstrap'
import Team from "../TeamPage/Team"
import ContactUs from "../common/ContactUs"

export default function TeamPage() {
  return (
    <Container fluid id='team'>
        <Team/>
        <ContactUs/>

    </Container>
  )
}
