import Button from 'react-bootstrap/Button';
import './CommonStyle.css'
function FormButton(props) {
  return ( 
    <div>
        <Button className="mainButton" type={props.type} variant="primary" size="lg" block>{props.Name}</Button>
    </div>


  );
}

export default FormButton;