import React, { useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import GrayTriangle from './../Images/Gray-Triangle.png'
import OrangeButton from '../common/OrangeButton';
import Aos from 'aos'
import 'aos/dist/aos.css'

export default function Market() {

 useEffect(()=>{
  Aos.init();
 }, [])
 
  return (

    <Container dir="rtl" className='backColor'>

        <Row>
            <Col sm={4} data-aos="flip-left" data-aos-offset="200">
                 <div className='place'>
                     <div className='innerMargin'>
                          <div className='title'>مكان لكل الحرفيين
                                <img className='shapes2' src={GrayTriangle} alt='Not Found'/>
                         </div>
                               <br></br>
                              من خلال ارتيزانا تقدر تعرض وتبيع منتجاتك
                               <br></br>
                               وتحصل على مصدر دخل اضافي
                     </div>
                </div>
            </Col>

            <Col sm={4} data-aos="flip-left" data-aos-offset="200" data-aos-delay="100">
            <div className='WithYou'>
                <div className='innerMargin'>
                     <div className='title'>لا تشيل هم
                         <img className='shapes2' src={GrayTriangle} alt='Not Found'/>
                    </div>
                         <br></br>
                         ارتيزانا تقوم بتخزين المنتجات وتوصيلها وتسويقها بدلا منك
                         <br></br>

<br></br>

                 </div>
           </div>

            </Col>

            <Col sm={4} data-aos="flip-left" data-aos-offset="200" data-aos-delay="200">
            <div className='Suggestion'>
                 <div className='innerMargin'>

                       <div className='title'>اقتراح حلول لمساعدتك
                           <img className='shapes2' src={GrayTriangle} alt='Not Found'/>

                        </div>
                            <br></br>
                         ارتيزانا توفر دورات تدريبية لتطوير مهاراتك المالية و التسويقية والتقنية

                 </div>
           </div>
            </Col>


        </Row>

        <Row>
        <div className='innerMargin2'>

        <OrangeButton Name="زيارة سوق أرتيزانا" Link="https://www.artizana.market/"/>
        </div>
         </Row>

    </Container>
    
  )
}
