import './HomePage.css'
function ArtizanaInfo() {
  return ( 
    <div dir="rtl">
       <p className="text-break text-24">  ارتيزانا هي سوق للتجارة الالكترونية تمكن الحرفيين من عرض وبيع منتجاتهم والاستفادة من حلول الدفع، خدمات التخزين والشحن بكل سلاسة. </p>
    </div>

  );
}

export default ArtizanaInfo;