import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import './ImageCard.css';
function ImageCard(props) {

  if (props.id ===props.picid) {
    return (
      <Card  className="border-0">
      <Card.Img variant="top"  src={props.image} className="move">
        </Card.Img>
        <Card.ImgOverlay className='d-flex align-items-center'>

        <Card.Text className='cardtext mx-auto '>
      {props.title}
        </Card.Text>

      </Card.ImgOverlay>
    </Card>

    );
  } else {

    
  return ( 
    <Card  className="border-0">
      <Card.Img variant="top" className="dontmove" src={props.image} >
        </Card.Img>
        <Card.ImgOverlay className='d-flex align-items-center'>

        <Card.Text className='cardtext mx-auto'>
      {props.title}
        </Card.Text>

      </Card.ImgOverlay>
    </Card>


  );
  }
}

export default ImageCard;