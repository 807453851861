import './HomePage.css';
import image1 from '././../Images/1.png';
import image2 from '././../Images/2.png';
import image3 from '././../Images/3.png';
import CardCarousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Image } from 'react-bootstrap';
import React from 'react';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
    paritialVisibilityGutter: 0
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    paritialVisibilityGutter: 0
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    paritialVisibilityGutter: 0
  }
};

var imageMoved = false;
class MultiImageCarousel extends React.Component  
  { 
  state = { imageMoved: ".dontmove", id:0 };
  render () {
  return ( 
<div>
<CardCarousel
      ssr
      centerMode={false}
      infinite
      arrows ={false}
      autoPlay
      autoPlaySpeed={1000}
      focusOnSelect={false}
      customTransition="all 1s linear"
      renderArrowsWhenDisabled={false}
      pauseOnHover
      deviceType={this.props.deviceType}
      additionalTransfrom={0}
  containerClass="container-with-dots"
  dotListClass=""
  draggable
  itemClass=""
  keyBoardControl
  minimumTouchDrag={80}
  renderButtonGroupOutside={false}
  renderDotsOutside={false}
      responsive={responsive}
    >
                <Image src={image1} />
                <Image src={image2} />
                <Image src={image3} />
                <Image src={image1} />
                <Image src={image2} />

    </CardCarousel>
</div>
  );
}
  }
export default MultiImageCarousel;

