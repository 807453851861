import React, { useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import blog1 from './../Images/blog1.PNG'
import blog2 from './../Images/blog2.PNG'
import blog3 from './../Images/blog3.PNG'
import blogMeter from './../Images/blogMeter.PNG'
import OrangeButton from '../common/OrangeButton';
import Aos from 'aos'
import 'aos/dist/aos.css'

export default function BlogSection() {

 useEffect(()=>{
  Aos.init();
 }, [])
 
  return (

    <Container dir="rtl" className="PaddingBlog">
<Row>
     <div className='paddingRight'>المدونة</div>
</Row>
        <Row>
            <Col sm={4} data-aos="zoom-in-up" data-aos-offset="200">
                 <div className='relative'>
                 <li>
                        <a href='https://www.artizana.market/' target="_blank" rel="noreferrer">
                     <img className='blog' src={blog1} alt='Not Found'/>
                        </a>
                    </li>
                </div>
                        
            </Col>

            <Col sm={4} data-aos="zoom-in-up" data-aos-offset="200" data-aos-delay="100">
                 <div className='relative'>
                 <li>
                        <a href='https://blog.artizana.store' target="_blank" rel="noreferrer">
                     <img className='blog' src={blog2} alt='Not Found'/>
                        </a>
                    </li>
                </div>
                         
            </Col>

            <Col sm={4} data-aos="zoom-in-up" data-aos-offset="200" data-aos-delay="200">
                 <div className='relative'>
                    <li>
                        <a href='https://blog.artizana.store' target="_blank" rel="noreferrer">
                     <img className='blog' src={blog3} alt='Not Found'/>
                        </a>
                    </li>
                 </div>
                      
            </Col>


        </Row>

        <Row>
            <div className='relative'>
            <img className='blog' src={blogMeter} alt='Not Found'/>
            </div>
        </Row>
        <Row>
        <div className='MarginBottom'>
        <OrangeButton Name="زيارة مدونة أرتيزانا" Link="https://blog.artizana.store"/>
        </div>
         </Row>



     
    </Container>
    
  )
}
