import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

export default function Eighth() {
  return (
    
    <Container className="P-background" dir="rtl"  fluid>

<Row>

<Col className='color1'>
<Row className='color2'></Row>

</Col>

<Col xs={6}>
    <div className='Princess'>
        <span className='princessName'>سمو الأميرة عبير بنت فيصل بن تركي آل سعود</span><br/>
        <p className='princessStatus'>رئيسة مجلس أمناء مجلس المنطقة الشرقية للمسؤولية الاجتماعية</p>
        <span className='princessParagraph'>“ الأسر المنتجة تعتبر قطاعاً ثالثاً اقتصادياً كبيراً، لابد من تنميته وذلك من خلال دعم هذه الأسر والتحول من المساعدة إلى المشاركة الفاعلة في الاقتصاد الوطني”</span>
      </div>
</Col>

    <Col className='color1'>
    <Row className='color2'></Row>

    </Col>

</Row>
    </Container>
  )
}