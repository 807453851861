import Button from 'react-bootstrap/Button';
import './CommonStyle.css'
function OrangeButton(props) {
  return ( 
    <div>
        <Button  href={props.Link} variant="primary" className="mainButton" size="lg" target='_blank' block>{props.Name}
        
        
        </Button>
    </div>


  );
}

export default OrangeButton;